import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IconPlusMinus from "../../images/plus-minus-icon.svg";
import IconRxBottle from "../../images/rx-bottle-icon.svg";
import IconPrevent from "../../images/HIV-prevention-icon.svg";
import IconSustain from "../../images/response-icon.svg";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

class Content extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }
  closeModal = () => {
    this.setState({ showModal: false });
  };
  showModal = () => {
    if ($(window).width() <= 767) {
      this.setState({ showModal: true });
    }
  };
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Modal
          centered
          show={this.state.showModal}
          onHide={this.closeModal}
          className="svg-modal"
        >
          <Modal.Body className="px-0">
            <img
              src={"/phase-1-target-jurisdictions-mobile.svg"}
              alt="Map of the US Showing Phase 1 Target Jurisdictions"
              className="w-100"
            />
          </Modal.Body>
        </Modal>
        <Container
          fluid
          className="hero ending-hiv"
          role="img"
          aria-label="Letterbox view of a
                      person's face"
        />
        <Container className="ending-hiv">
          <Row>
            <Col xs={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title">
                  <span className="sub-title d-block">
                  Plans for Ending the HIV Epidemic
                  </span>
                  National Efforts to End the Epidemic by 2030
                </h1>
              </div>
              <Container>
                <Row>
                  <Col xs={12} className="px-0 pr-2">
                    <p>
                      Since the beginning of the epidemic, HIV experts have
                      noted the importance of having a plan that would set clear
                      goals for the United States and coordinate efforts to
                      address the epidemic across government agencies.
                      <sup>1</sup> The first such plan, the{" "}
                      <em>National HIV/AIDS Strategy,</em> was implemented in
                      2010 and has been credited with helping to reduce rates of
                      new HIV infections through 2019.<sup>2</sup>
                    </p>
                    <p>
                      As the HIV epidemic has changed over the last decade,
                      there have been a few iterations of this plan and the
                      introduction of another. Today, these two national plans—
                      <em>
                        Ending the HIV Epidemic in the United States: A Plan for
                        America
                      </em>{" "}
                      and the{" "}
                      <em>
                        National HIV/AIDS Strategy for the United States
                        2022&ndash;2025
                      </em>
                      —guide and coordinate efforts to help end the HIV epidemic
                      across the country. Both plans share the goal of bringing
                      new HIV infections down 75% by 2025 and 90% by 2030.
                      <sup>2,3</sup>
                    </p>
                    <p>
                      These plans take separate but complementary approaches to
                      ensuring widespread access to prevention, treatment, and
                      care, and they guide different aspects of the government’s
                      response.<sup>4</sup>
                    </p>
                  </Col>
                  <Col xs={12} className="px-0">
                    <h2 className="title">
                      ENDING THE HIV <br className="d-block d-md-none" />
                      EPIDEMIC IN THE UNITED STATES: A PLAN FOR AMERICA
                    </h2>
                    <p>
                      <em>
                        Ending the HIV Epidemic (EHE) in the United States: A
                        Plan for America,
                      </em>{" "}
                      known as the Initiative, is a federal effort designed to
                      reduce new HIV infections by 90% by 2030. The Initiative,
                      launched in 2019, has three phases.<sup>3</sup>
                    </p>
                    <p>
                      Phase I, which is already underway, focuses on those US
                      jurisdictions hardest hit by the HIV epidemic. There are
                      57 target jurisdictions, which include the 48 counties
                      with the highest number of new HIV diagnoses in 2016 and
                      2017: San Juan, Puerto Rico, and Washington, DC. More than
                      50% of new HIV diagnoses occurred in those locations
                      during that time period. Also included as target
                      jurisdictions are seven states with a substantial rural
                      burden, with over 75 cases and 10% or more of their new
                      HIV diagnoses occurring in 2016 and 2017 in rural areas.
                      <sup>3,5</sup>
                    </p>
                    <p className="">
                      Phase II of the Initiative, which begins in 2026, will
                      expand to address needs across the country, and Phase III
                      will be more focused on case management to keep the number
                      of new HIV infections at fewer than 3000 per year.
                      <sup>3,6</sup>
                    </p>
                    <Container className="pillars">
                      <Row>
                        <Col xs={12} className="px-0 pt-1">
                          <h3>
                            Pillars of the Initiative
                          </h3>
                          <p>
                            The Initiative is organized around four pillars of
                            action<sup>3</sup>:
                          </p>
                        </Col>
                        <Col xs={12} lg={2} className="cont-icon">
                          <img className="icon" src={IconPlusMinus} alt="Plus and minus diagnosis icon" />
                        </Col>
                        <Col xs={12} lg={10}>
                          <p className="desc">
                            <span className="cont-hang bullet">
                              <span className="hang">&#8226;</span>
                            </span>{" "}
                            <span className="font-red font-heavy">
                              Diagnose.
                            </span>{" "}
                            <em>
                              The Initiative aims to reduce the number of people
                              living with HIV who are unaware of their status.
                            </em>
                            <sup>7</sup> As of 2021, it is estimated that about
                            13% of people living with HIV did not know they were
                            infected, and that nearly 40% of new infections in
                            2016 involved someone who was unaware of their
                            status.<sup>7-9</sup> The Initiative is working to
                            increase access to HIV testing in target areas to
                            help diagnose new infections as quickly as possible
                            <sup>7</sup>
                          </p>
                        </Col>
                        <Col xs={12} lg={2} className="cont-icon">
                          <img className="icon" src={IconRxBottle} alt="Prescription pill bottle icon" />
                        </Col>
                        <Col xs={12} lg={10} className="cont-icon">
                          <p className="desc">
                            <span className="cont-hang bullet">
                              <span className="hang">&#8226;</span>
                            </span>{" "}
                            <span className="font-red font-heavy">Treat.</span>{" "}
                            <em>
                              The Initiative aims to ensure that everyone living
                              with HIV is getting the treatment they need with
                              the goal of helping everyone achieve viral
                              suppression.
                            </em>
                            * Everyone who is diagnosed with HIV should see a
                            healthcare provider as soon as possible. Today’s
                            treatments have been shown to be effective at
                            suppressing the virus if used as prescribed by a
                            healthcare provider. This can help those with HIV
                            live longer, healthier lives and can help reduce the
                            chance of passing HIV to others through sex. While
                            strides have been made in getting treatment to more
                            people, in 2021 only 66% of people aged 13 and older
                            living with diagnosed HIV were virally suppressed or
                            undetectable.<sup>10,</sup>*<sup>,&dagger;</sup>{" "}
                            Experts also recognize Treatment as Prevention
                            (TasP) and understand that access to it is a vital
                            part of ending new HIV infections
                            <sup>7,11</sup>
                          </p>
                        </Col>
                        <Col xs={12} lg={2} className="cont-icon">
                          <img className="icon" src={IconPrevent} alt="Condom icon"/>
                        </Col>
                        <Col xs={12} lg={10} className="cont-icon">
                          <p className="desc">
                            <span className="cont-hang bullet">
                              <span className="hang">&#8226;</span>
                            </span>{" "}
                            <span className="font-red font-heavy">
                              Prevent.
                            </span>{" "}
                            <em>
                              The Initiative aims to increase prevention
                              efforts, including pre-exposure prophylaxis (PrEP)
                              medicine.
                            </em>{" "}
                            PrEP is a prevention strategy for people who are at
                            risk for HIV. It involves using a prescription
                            medicine to reduce the risk of HIV infection.
                            Research shows that PrEP medicine is highly
                            effective if used correctly. The Centers for Disease
                            Control and Prevention (CDC) recommends PrEP
                            medicine for anyone who is at high risk of getting
                            HIV, but as of 2022, only 31% of the estimated 1.2
                            million people who could benefit from PrEP filled
                            prescriptions for it.<sup>7,12,13</sup>
                          </p>
                        </Col>
                        <Col xs={12} lg={2} className="cont-icon">
                          <img className="icon" src={IconSustain} alt="Public health response icon"/>
                        </Col>
                        <Col xs={12} lg={10} className="cont-icon">
                          <p className="desc">
                            <span className="cont-hang bullet">
                              <span className="hang">&#8226;</span>
                            </span>{" "}
                            <span className="font-red font-heavy">
                              Respond.
                            </span>{" "}
                            <em>
                              The Initiative aims to improve the public health
                              response to new outbreaks.
                            </em>{" "}
                            New testing techniques and more sophisticated
                            disease tracking systems, such as molecular
                            surveillance, allow public health officials to
                            pinpoint potential HIV outbreaks. The federal
                            government can then work with local health
                            departments in those areas to provide a timely
                            response with tailored interventions and increased
                            testing, treatment, and prevention services in those
                            areas most in need<sup>7,14</sup>
                          </p>
                        </Col>
                        <p>
                          The Department of Health and Human Services (HHS)
                          coordinates the Initiative through the Office of the
                          Assistant Secretary for Health (OASH). The
                          Initiative is a collaboration between many agencies
                          within the department, including the CDC, the Health
                          Resources and Services Administration (HRSA), Indian
                          Health Service (IHS), National Institutes of Health
                          (NIH), and Substance Abuse and Mental Health
                          Services Administration (SAMHSA).<sup>15</sup>
                        </p>
                        <ul class="footnote-list">
                          <li>*Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                          </li>
                          <li><sup>&dagger;</sup>Among people aged 13 and older with diagnosed HIV
                          in 47 states and the District of Columbia.</li>
                        </ul>
                      </Row>
                    </Container>
                    <h3>
                      Plans and Programs of the Initiative
                    </h3>
                    <p>
                      The Initiative is guiding new plans and programs on the
                      national, state, and <br className="d-block d-md-none" />
                      local levels.
                    </p>
                    <div className="plans-and-programs">
                      <ul>
                        <li>
                          <strong>National Level.</strong> America's HIV Epidemic Analysis Dashboard (AHEAD) is a data visualization
                          project that tracks public health goals via six HIV
                          indicators in target jurisdictions to help highlight
                          progress and pinpoint areas that still need more help
                          <sup>19</sup>
                        </li>
                        <li>
                          <strong>State Level.</strong> States with high HIV
                          rates—including Alabama, Arkansas, Kentucky,
                          Mississippi, Missouri, Oklahoma, and South
                          Carolina—have worked with federal agencies to develop
                          coordinated plans to bring down transmission and
                          increase testing and care in the most highly impacted
                          areas<sup>5,20</sup>
                        </li>
                        <li>
                          <strong>Local Level.</strong> The federal government is also working with each of the target jurisdictions to develop local plans to reduce HIV transmission. To accelerate progress, health data related to local conditions and environments are combined with community input to make sure these plans can have the most impact.<sup>20</sup>
                        </li>
                      </ul>
                    </div>
                    <p>
                      These areas highlight how different communities are
                      struggling with varying aspects of the epidemic and why
                      interventions need to be specifically tailored to the
                      population and issues they’re facing. These programs will
                      help inform best practices as other priority areas create
                      their plans and as the Initiative expands to cover more
                      areas of the country in Phase II.<sup>21</sup>
                    </p>
                    <div className="chart-bg w-100 mx-0 mb-4">
                      <div
                        onClick={this.showModal}
                        className="cont-chart ending"
                      >
                        <h3>
                          THE INITIATIVE: <br className="d-block d-md-none" />
                          PHASE I TARGET JURISDICTIONS
                        </h3>
                        <div className="chart-area">
                          <div className="cont-img p-4">
                            <img
                              className="d-none d-md-block w-100"
                              src={"/HIV-Initiative-Phase-1-target-juristictions.svg"}
                              alt="Map of the US Showing Phase 1 Target Jurisdictions"
                            />
                            <img
                              className="d-block d-md-none w-100"
                              src={"/HIV-Initiative-Phase-1-target-juristictions-mobile.svg"}
                              alt="Map of the US Showing Phase 1 Target Jurisdictions"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="footnote">
                        Source: Adapted from the Office of Infectious Disease
                        and HIV/AIDS Policy (OIDP) at Department of Health and
                        Human Services (HHS).&nbsp;
                        <a
                          target="_blank"
                          href="https://www.hiv.gov/federal-response/ending-the-hiv-epidemic/jurisdictions"
                        >
                          Priority Jurisdictions:&nbsp;
                          <span className="emphasis">Phase I</span>
                        </a>
                        , 2020.&nbsp;About Ending the HIV Epidemic in the U.S.
                      </p>
                    </div>
                    <h3>Funding for the Initiative</h3>
                    <p>
                      To meet the goals of this plan, the government has
                      steadily increased funding for HIV prevention, treatment,
                      and care. The funding goes to a number of federal agencies
                      that run national programs and distribute the funds to
                      state and local governments and organizations across the
                      country.<sup>6</sup>
                    </p>
                    <p>
                      For fiscal year 2023 (which ran from October 1, 2022 to
                      September 30, 2023), Congress included $573.3M for
                      implementation of the Initiative. This included
                      <sup>22</sup>
                      <span style={{ position: "relative", left: "1px" }}>
                        :
                      </span>
                    </p>
                    <div className="white-box funding">
                      <ul>
                        <li>
                          <strong>$220M</strong> for the CDC to implement
                          high-impact HIV prevention and reduce new HIV
                          infections
                        </li>
                        <li>
                          <strong>$165M</strong> for HIV care through the Ryan
                          White HIV/AIDS Program
                        </li>
                        <li>
                          <strong>$157M</strong> to expand access to HIV
                          testing, linkage to care, and prescription of HIV PrEP
                          and associated services through the Health Center
                          Program
                        </li>

                        <li>
                          <strong>$5M</strong> to the IHS for diagnosis,
                          prevention, and treatment initiatives for HIV, HCV,
                          and STIs
                        </li>
                        <li>
                          <strong>$26M </strong> to the NIH for implementation of
                          research projects in the EHE jurisdictions
                        </li>
                      </ul>
                    </div>
                    <p>
                      The proposed budget for fiscal year 2024 includes $850M,
                      which represents a $277M increase over the previous year’s
                      enacted funding level. The document states that the budget
                      focuses on the need "...to aggressively reduce new HIV
                      cases, increase access to pre-exposure prophylaxis (also
                      known as PrEP), and ensure equitable access to services
                      and support for those living with HIV."
                      <sup>22,23</sup>
                    </p>
                    <h4 className="title">Progress on the initiative</h4>
                    <p>
                      As of 2021, the Initiative had made significant progress
                      with its national programs and its state and local
                      efforts.<sup>3,21</sup>
                    </p>
                    <div className="white-box progress-initiative">
                      <ul>
                        <li>
                          At EHE-funded Health Centers, the Health Resources and
                          Services Administration’s (HRSA) Bureau of Primary
                          Health Care (BPHC) served nearly <strong>9M</strong>{" "}
                          patients, of which <strong>79%</strong> were racial
                          and ethnic minorities
                        </li>
                        <li>
                          HRSA Health Centers conducted <strong>1.7M</strong>{" "}
                          HIV tests in <strong>2021</strong>
                        </li>
                        <li>
                          <li>
                            <strong>3</strong> jurisdictions met the{" "}
                            <strong>2025</strong> goal of linking{" "}
                            <strong>95%</strong> of newly diagnosed people to
                            HIV care
                          </li>
                        </li>
                        <li>
                          <strong>5</strong> jurisdictions met the{" "}
                          <strong>2025</strong> goal by linking or prescribing
                          PrEP for at least <strong>50%</strong> of eligible
                          people
                        </li>
                        <li>
                          <strong>227</strong> clusters of HIV infections were
                          reported to CDC and addressed by <strong>41</strong>{" "}
                          health departments from <strong>2020 to 2021</strong>
                        </li>
                      </ul>
                    </div>
                    <h2 className="title mt-4 pt-3">
                      THE NATIONAL HIV/AIDS STRATEGY FOR THE UNITED STATES
                    </h2>
                    <p>
                      The <em>National HIV/AIDS Strategy,</em> released in 2010,
                      identified priorities, coordinated resources, and offered
                      clear and measurable targets for prevention and care
                      interventions. This was the first national plan to address
                      HIV and it has been credited with increasing collaboration
                      and coordination of efforts across the many agencies and
                      groups that are working to end the HIV epidemic. The
                      original strategy was updated in 2015 and twice in 2021.
                      <sup>2,24</sup> The current{" "}
                      <em>
                        National HIV/AIDS Strategy for the United States
                        2022&ndash;2025
                      </em>{" "}
                      builds on the decade of work under previous iterations.
                      <sup>2</sup>
                    </p>
                    <p>
                      The Strategy focuses on four goals that are similar to,
                      though not the same as, the pillars in the Initiative.
                      They are:
                    </p>
                    <div className="strategic-plan">
                      <ul>
                        <li>
                          <strong>Prevent new HIV infections.</strong> The
                          Strategy aims to increase awareness of HIV, knowledge
                          of HIV status, and implementation of safe and
                          effective prevention interventions such as PrEP
                          medicine, post-exposure prophylaxis (PEP), and syringe
                          services programs (SSPs). It also aims to increase the
                          diversity and capacity of healthcare delivery systems
                          as well as community health, public health, and the
                          health workforce to prevent and diagnose HIV.
                          <sup>2</sup>
                        </li>
                        <li>
                          <strong>Improve HIV-related health outcomes of people with
                            HIV.
                          </strong>{" "}
                          Among other things, the Strategy aims to link people
                          living with HIV to care immediately after diagnosis,
                          remove barriers to HIV treatment, identify those who
                          are not in care or not virally suppressed to engage or
                          reengage them in care, provide integrative HIV
                          services for HIV-associated comorbidities,
                          coinfections, and complications, and increase
                          adherence to HIV treatment and retention in care to
                          achieve and maintain long-term viral suppression.* It
                          also aims to expand the capacity to provide
                          whole-person care to long-term survivors and older
                          adults living with HIV, advance the developments of
                          next-generation HIV therapies, and accelerate research
                          for an HIV cure.<sup>2</sup>
                        </li>
                        <li>
                          <strong>Reduce HIV-related disparities and health
                            inequities.
                          </strong>{" "}
                          Marginalized communities—including men who have sex
                          with men (MSM) and people of color—have been
                          disproportionately impacted by HIV/AIDS since the
                          beginning of the epidemic.<sup>25</sup> This
                          disproportionate impact is especially evident in
                          Black/African American females, who accounted for 54%
                          of new HIV diagnoses among females in the US in 2021.
                          <sup>26,&dagger;</sup> Similarly, transgender women
                          are also highly impacted, with an HIV prevalence of
                          14.1% compared to an estimated &lt;0.5% of US adults
                          overall, according to data published in 2019.
                          <sup>27</sup> The Strategy acknowledges and seeks to
                          reduce these disparities as well as stigma and
                          discrimination, and address the policy issues, social
                          and structural determinants of health, and
                          co-occurring conditions that impede access to HIV
                          services and exacerbate HIV in some communities. The
                          Strategy aims to achieve these goals by employing and
                          providing public leadership opportunities for people
                          living with or at risk for HIV, training and expanding
                          a diverse HIV workforce, advancing HIV-related
                          communications to achieve improved messaging and
                          uptake, and addressing misinformation and healthcare
                          mistrust.<sup>2</sup>
                        </li>
                        <li>
                          <strong>Achieve integrated, coordinated efforts among all
                            partners and interested parties.
                          </strong>{" "}
                          Among other things, the Strategy aims to integrate HIV
                          prevention and treatment efforts across all levels of
                          government (state, federal, community-based, local,
                          and territorial) with those of organizations (public,
                          private, academic, community-based, and faith-based)
                          working on these issues. It also suggests a holistic
                          approach to prevention and treatment of HIV in the
                          context of other often co-occurring health conditions
                          such as STIs, viral hepatitis, substance use, and
                          mental health disorders. Moreover, it seeks to
                          integrate HIV awareness with outreach and services for
                          other intersecting issues such as intimate partner
                          violence and housing instability. Finally, it seeks to
                          improve the quality, accessibility, sharing, and uses
                          of HIV prevention, care, and other data, and to
                          advance methods of measuring, monitoring, evaluating,
                          and using these data to course correct as needed to
                          achieve the Strategy's goals.<sup>2</sup>
                        </li>
                      </ul>
                    </div>
                    <p>
                      Rather than focus on priority geographic areas like the
                      Initiative, the Strategy focuses on priority groups—those
                      who are disproportionately impacted by the epidemic. These
                      groups include<sup>2</sup>:
                    </p>
                    <div class="white-box strategic-plan">
                      <ul>
                        <li>
                          MSM (in particular Black American, Hispanic/Latino
                          American, and American Indian/Alaska Native men)
                        </li>
                        <li>Black American women</li>
                        <li>Transgender women</li>
                        <li>Young people ages 13 to 24</li>
                        <li>People who inject drugs</li>
                      </ul>
                    </div>
                    
                    <p>
                      The current Strategy places an added emphasis on older
                      adults living with HIV and long-term survivors. Objectives
                      include identifying models of care that meet the needs of
                      people with HIV who are aging, implementing best practices
                      related to addressing psychosocial and behavioral health
                      needs of older adults living with HIV and long-time
                      survivors, and promoting research and collaboration to
                      address specific aging-related conditions in people living
                      with HIV.<sup>2</sup>
                    </p>
                    <p>
                      The Strategy also acknowledges that other populations need
                      specific attention and tailored services based on their
                      unique circumstances, such as sex workers, immigrants,
                      people experiencing housing instability, individuals with
                      disabilities, and those involved with the justice system.
                      <sup>2</sup>
                    </p>
                    <p>
                      The Strategy identifies viral suppression* as the main
                      indicator of success in reducing health disparities and
                      achieving health equity among these groups; viral
                      suppression helps improve the health of individuals and
                      reduces HIV-related deaths while also helping to prevent
                      new HIV infections. As the Strategy explains, “Monitoring
                      progress on these disparities indicators also helps ensure
                      that the nation is making progress with all populations,
                      leaving no groups behind.”<sup>2</sup>
                    </p>
                    <p>
                      Coordinated efforts under earlier versions of the Strategy
                      have been credited with some of the recent successes in
                      controlling the HIV epidemic. Between 2010 and 2019, the
                      number of people living with HIV who achieved viral
                      suppression* increased nearly 30% (from 28% to 57%), the
                      number of new HIV diagnoses declined about 14%, and annual
                      HIV-related deaths dropped by about 7%.<sup>2</sup>
                    </p>
                    <p>
                      Earlier versions of the Strategy also helped change the
                      policies around how HIV programs are funded to ensure that
                      resources are going to the places and populations that
                      need them most. Earlier in the epidemic, funding was
                      allocated on a discretionary basis or based on cumulative
                      AIDS cases, which skewed toward cities that were most
                      impacted early in the epidemic but left new hotspots
                      underfunded. The Strategy has also facilitated increases
                      in health insurance coverage rates for people living with
                      HIV, as well as investments in NIH research focused on new
                      HIV treatment and prevention options, vaccine development,
                      and ultimately, a cure.<sup>2</sup>
                    </p>
                    <ul class="footnote-list">
                      <li>*Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup></li>
                      <li><sup>†</sup>Among females aged 13 and older.</li>
                    </ul>
                    <h2 className="title mt-4 pt-3">
                      Different Priorities and Same Goal
                    </h2>
                    <p>
                      <em>
                        Ending the HIV Epidemic in the United States: A Plan for
                        America
                      </em>{" "}
                      and{" "}
                      <em>
                        The National HIV/AIDS Strategy for the United States
                        2022&ndash;2025
                      </em>{" "}
                      are both working toward the goal of reducing new HIV
                      infections by 2030 and are designed to complement each
                      other.<sup>2,4</sup> The Initiative recognizes that the
                      current epidemic can best be viewed as diverse
                      microepidemics with different underlying causes and,
                      therefore, different solutions. The Initiative is focused
                      on those areas most in need and allows the federal
                      government to work closely with local authorities to
                      tailor prevention and treatment efforts.<sup>3</sup>
                    </p>
                    <p>
                      In contrast, the Strategy takes a broader look at the
                      epidemic in this country and focuses on those groups of
                      people that continue to be disproportionately impacted by
                      HIV. It recognizes that these disparities are driven by
                      society-wide issues from racism and discrimination to
                      housing and education, and envisions whole-of-society
                      solutions.<sup>2</sup>
                    </p>
                    <p>
                      Both plans are making progress, but the COVID-19 pandemic
                      has created additional challenges for the agencies charged
                      with implementation.<sup>2,3,22,24</sup> Public health
                      professionals—such as front-line healthcare providers,
                      researchers, and trained contact tracers—have been pulled
                      away from HIV work to focus on COVID-19 efforts.
                      <sup>6</sup>
                    </p>
                    <p>
                      Emerging research on the early months of the pandemic
                      suggests that both HIV testing rates and PrEP medication
                      use were down.<sup>28,29</sup> For example, four large
                      metropolitan areas showed a 27% to 59% reduction in the
                      total number of HIV tests conducted at outpatient settings
                      in 2020 compared to the previous year.<sup>28</sup>{" "}
                      Similarly, a national pharmacy-based analysis estimated
                      there was a 22% decrease in the total number of PrEP
                      prescriptions and a 25% decrease in the total number of
                      new PrEP users between March 2020 and March 2021 compared
                      to predicted data had the COVID-19 pandemic never
                      occurred.<sup>29</sup>
                    </p>
                    <p>
                      During the pandemic, Congress allocated additional funds
                      to both the Ryan White HIV/AIDS Program and the Housing
                      Opportunities for People with AIDS (HOPWA) Program to
                      help those impacted by COVID-19.<sup>6</sup>
                    </p>
                    <p>
                      Despite the added challenges brought on by the global
                      pandemic, experts believe we are on track to meet our goal
                      of reducing new HIV infections 90% by 2030.<sup>30</sup>
                    </p>
                  </Col>
                  <BackToTop />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
